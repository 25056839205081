import React from 'react'
import moment from 'moment'
//React router dom
import { Link, useLocation } from 'react-router-dom'

//Ant design
import {
    ClockCircleOutlined,
    DashboardOutlined,
    ExceptionOutlined,
    HomeOutlined,
    IdcardOutlined,
    PictureOutlined,
    SettingOutlined,
    ShopOutlined,
    ShoppingOutlined,
    TeamOutlined,
    ThunderboltFilled,
    ThunderboltOutlined
} from '@ant-design/icons'
import { Badge } from 'antd'

export const dataPickupStatus = [
    {
        status: 'Ongoing',
        name: 'Ongoing',
        selcetKey: 'pick-ongoing'
    },
    {
        status: 'Completed',
        name: 'Completed',
        selcetKey: 'pick-completed'
    },
    {
        status: 'Cancelled',
        name: 'Cancelled',
        selcetKey: 'pick-cancelled'
    }
]

export const dataB2BPickupStatus = [
    {
        status: 'Ongoing',
        name: 'Ongoing',
        selcetKey: 'b2b-pick-ongoing'
    },
    {
        status: 'Completed',
        name: 'Completed',
        selcetKey: 'b2b-pick-completed'
    },
    {
        status: 'Cancelled',
        name: 'Cancelled',
        selcetKey: 'b2b-pick-cancelled'
    }
]

export const dataOrderStatus = [
    {
        status: 'user_order_received',
        name: 'Current orders'
    },
    {
        status: 'vendor_order_placed',
        name: 'Ordered by vendors'
    },
    {
        status: 'items_received',
        name: 'Orders in warehouse'
    },
    {
        status: 'executive_assigned',
        name: 'Assigned delivery'
    },
    {
        status: 'executive_out_for_delivery',
        name: 'Out for delivery'
    },
    {
        status: 'order_delivered',
        name: 'Completed orders'
    },
    {
        status: 'cancelled',
        name: 'Cancelled orders'
    },
    {
        status: 'replace-orders',
        name: 'Replacement orders',
        children: [
            {
                status: 'replace_requested',
                name: 'Replacement requested'
            },
            {
                status: 'replaced',
                name: 'Replacement Completed'
            }
        ]
    }
]

export const dataCustomerSupport = [
    {
        status: 'user-query',
        name: 'Users',
        link: '',
        key: 'user-query',
        icon: <>H</>
    },
    {
        status: 'business-query',
        name: 'Business',
        link: '',
        key: 'business-query',
        icon: <>H</>
    },
    {
        status: 'pickup-query',
        name: 'Pickup / Delivery agents',
        link: '',
        key: 'pickup-query',
        icon: <>H</>
    },
    {
        status: 'warehouse-query',
        name: 'Warehouse Manager',
        link: '',
        key: 'warehouse-query',
        icon: <>H</>
    },
    {
        status: 'driver-query',
        name: 'Driver',
        link: '',
        key: 'driver-query',
        icon: <>H</>
    }
]

export const masterDirectory = [
    {
        link: '/cancellation-reason',
        key: 'cancellation-reason',
        name: 'Cancellation-reason',
        icon: '',
        children: [
            {
                link: '/cancellation-reason/c-pickup',
                key: 'c-pickup',
                name: 'Pickup orders',
                icon: ''
            },
            {
                link: '/cancellation-reason/c-eco-store',
                key: 'c-eco-store',
                name: 'Eco store orders',
                icon: ''
            }
        ]
    },
    {
        link: '/pickup-executives/list-pickup-executives',
        key: 'pickexecutive',
        name: 'Pickup executive',
        icon: <>H</>
    },

    {
        link: '/eco-driver/driver-list',
        key: 'driver',
        name: 'Driver',
        icon: ''
    },
    {
        link: '/warehouse-and-manager/warehouse-list',
        key: 'warehousemanage',
        name: 'Warehouse / Manager',
        icon: ''
    },
    {
        link: '/eco-store/list-eco-stores',
        key: 'ecostores',
        name: 'Eco store',
        icon: ''
    },
    {
        link: '/rate-card/list-rate-card',
        key: 'ratecard',
        name: 'Ratecard',
        icon: ''
    },
    {
        link: '/b2b-rate-card/b2b-list-rate-card',
        key: 'b2b-ratecard',
        name: 'B2B Ratecard',
        icon: ''
    },
    {
        link: '/payment-modes/list-payment-modes',
        key: 'paymentmode',
        name: 'Payment mode',
        icon: ''
    },
    {
        link: '/NGOs',
        key: 'NGOs',
        name: 'NGOs',
        icon: ''
    },
    {
        link: '/configuration',
        key: 'ecocoins',
        name: 'Configuration',
        icon: ''
    },
    {
        link: '/outward-client',
        key: 'outward-client',
        name: 'Outward client',
        icon: ''
    },
    {
        link: '/groups-pincodes',
        key: 'groups-pincodes',
        name: 'Groups / Pincodes',
        icon: ''
    },
    {
        link: '/festive-offers',
        key: 'festive-offers',
        name: 'Festive offers',
        icon: ''
    }
]

export const settlements = [
    {
        link: '/pickup-expenses/list-pickup-expenses',
        key: 'pickup-expenses',
        name: 'Pickup expenses',
        icon: ''
    },
    {
        link: '/admin-expenses',
        key: 'admin-expenses',
        name: 'Admin expenses',
        icon: ''
    },
    {
        link: '/NGO-payments',
        key: 'NGO-payments',
        name: 'NGO payments',
        icon: ''
    },
    {
        link: '/users/referral-list',
        key: 'referrals',
        name: 'Referrals',
        icon: ''
    }
]

export function getFilteredRoutes(routeNames, routes) {
    let filteredRoutes = {}

    const filterRoutesRecursively = (innerRouteNames, routes) => {
        let result = {}
        routeNames?.forEach((routeName) => {
            if (routes[routeName]) {
                result[routeName] = { ...routes[routeName], key: routeName }
                if (routes[routeName].children) {
                    let childRouteNames = Object.keys(routes[routeName].children).filter((name) =>
                        innerRouteNames.includes(name)
                    )
                    result[routeName].children = filterRoutesRecursively(childRouteNames, routes[routeName].children)
                }
            }
        })

        return result
    }
    filteredRoutes = filterRoutesRecursively(routeNames, routes)
    return filteredRoutes
}

function getIcon(key, condition, selectedKeys, collapsed, b2b_Pending_Request, open_C_Issues, ecoPlacedOrders) {
    switch (key) {
        case 'dashboard':
            return <DashboardOutlined className={condition ? 'ant-menu-item-selected' : ''} />
        case 'admin_users':
            return <IdcardOutlined className={condition ? 'ant-menu-item-selected' : ''} />
        case 'users':
            return <TeamOutlined className={condition ? 'ant-menu-item-selected' : ''} />
        case 'businesses':
            return (
                <Badge offset={[5, 5]} dot={collapsed === true && b2b_Pending_Request > 0 ? true : false}>
                    <ShopOutlined
                        style={{
                            color:
                                selectedKeys == 'pending' || selectedKeys == 'active' || selectedKeys == 'rejected'
                                    ? '#1bba6e'
                                    : '#ffffff'
                        }}
                        className={condition ? 'ant-menu-item-selected' : ''}
                    />
                </Badge>
            )
        case 'slots':
            return <ClockCircleOutlined className={condition ? 'ant-menu-item-selected' : ''} />
        case 'feeds':
            return <PictureOutlined className={condition ? 'ant-menu-item-selected' : ''} />
        case 'pickup_orders':
            return <ThunderboltOutlined className={condition ? 'ant-menu-item-selected' : ''} />
        case 'b2b_pickup_orders':
            return <ThunderboltFilled className={condition ? 'ant-menu-item-selected' : ''} />
        case 'eco_store_orders':
            return (
                <Badge offset={[5, 5]} dot={collapsed === true && ecoPlacedOrders > 0 ? true : false}>
                    <ShoppingOutlined
                        style={{
                            color:
                                selectedKeys == 'user_order_received' ||
                                selectedKeys == 'vendor_order_placed' ||
                                selectedKeys == 'items_received' ||
                                selectedKeys == 'executive_assigned' ||
                                selectedKeys == 'executive_out_for_delivery' ||
                                selectedKeys == 'order_delivered' ||
                                selectedKeys == 'cancelled' ||
                                selectedKeys == 'replace_requested' ||
                                selectedKeys == 'replaced'
                                    ? '#1bba6e'
                                    : '#ffffff'
                        }}
                        className={condition ? 'ant-menu-item-selected' : ''}
                    />
                </Badge>
            )
        case 'customer_issues':
            return (
                <Badge
                    offset={[5, 5]}
                    dot={
                        collapsed === true &&
                        (open_C_Issues.user > 0 ||
                            open_C_Issues.business > 0 ||
                            open_C_Issues.driver > 0 ||
                            open_C_Issues.executive > 0 ||
                            open_C_Issues.manager > 0)
                            ? true
                            : false
                    }>
                    <ExceptionOutlined
                        style={{
                            color:
                                selectedKeys == 'query_users' ||
                                selectedKeys == 'query_business' ||
                                selectedKeys == 'query_pickups' ||
                                selectedKeys == 'query_warehouse_manager' ||
                                selectedKeys == 'query_driver'
                                    ? '#1bba6e'
                                    : '#ffffff'
                        }}
                        className={condition ? 'ant-menu-item-selected' : ''}
                    />
                </Badge>
            )
        case 'master_directory':
            return <ShoppingOutlined className={condition ? 'ant-menu-item-selected' : ''} />
        case 'settlements':
            return <SettingOutlined className={condition ? 'ant-menu-item-selected' : ''} />
        case 'warehouse':
            return <HomeOutlined className={condition ? 'ant-menu-item-selected' : ''} />
    }
}
export function transformWarehouseToAntMenu(selectedKeys, warehouses, onClearWarehoseStates) {
    const warehouseItem = warehouses?.map((item) => {
        const antMenuItem = {
            key: item?.id,
            label: (
                <Link
                    state={{ ...item, mainWarehouse: true }}
                    onClick={() => {
                        onClearWarehoseStates()
                    }}
                    to={`/warhouse-and-manager/warhouse-details/${item?.id}`}>
                    {item && item?.area_name}
                </Link>
            ),
            disabled: false, // You may need to determine this based on your logic
            icon: null,
            children: null,
            theme: 'dark'
        }
        return antMenuItem
    })
    return warehouseItem
}

export function transformRouteToAntMenu(
    route,
    selectedKeys,
    onClearStates,
    onClearWarehoseStates,
    b2b_Pending_Request,
    open_C_Issues,
    ecoPlacedOrders,
    collapsed,
    warehouses
) {
    const antMenu = []
    const { pathname } = useLocation()

    for (const key in route) {
        const { name, path, children } = route[key]
        const fullPath = `${path}`

        const antMenuItem = {
            key: key,
            label: fullPath.includes('/') ? (
                <Link
                    onClick={() => {
                        onClearStates()
                    }}
                    state={{ status: key }}
                    to={`${fullPath}`}>
                    {name}
                </Link>
            ) : (
                fullPath
            ),
            disabled:
                (key == 'business_ongoing' ||
                    key == 'business_completed' ||
                    key == 'business_cancelled' ||
                    key == 'pickup_ongoing' ||
                    key == 'pickup_completed' ||
                    key == 'pickup_cancelled') &&
                pathname == path
                    ? true
                    : false, // You may need to determine this based on your logic
            icon: getIcon(
                key,
                selectedKeys == key,
                selectedKeys,
                collapsed,
                b2b_Pending_Request,
                open_C_Issues,
                ecoPlacedOrders
            ),
            children: null,
            theme: 'dark'
        }

        if (children) {
            if (key == 'warehouse') {
                antMenuItem.children = transformWarehouseToAntMenu(selectedKeys, warehouses, onClearWarehoseStates)
            } else {
                antMenuItem.children = transformRouteToAntMenu(
                    children,
                    selectedKeys,
                    onClearStates,
                    onClearWarehoseStates,
                    b2b_Pending_Request,
                    open_C_Issues,
                    ecoPlacedOrders,
                    collapsed,
                    warehouses
                )
            }
        }
        antMenu.push(antMenuItem)
    }

    return antMenu
}
export function findPath(rolesArray, routes, key, visited = {}) {
    for (let singleRoute in routes) {
        if (singleRoute === key) {
            if (routes[singleRoute].children) {
                for (let accessibleRoute in rolesArray) {
                    for (let childrenRoute in routes[singleRoute].children) {
                        if (childrenRoute == rolesArray[accessibleRoute]) {
                            return routes[singleRoute].children[childrenRoute].path
                        }
                    }
                }
            } else {
                return routes[singleRoute].path
            }
        }
        if (routes[singleRoute].children && !visited[singleRoute]) {
            visited[singleRoute] = true
            let found = findPath(rolesArray, routes[singleRoute].children, key, visited)
            if (found) return found
        }
    }
}
export const amountConverterDecimal = (amount) => {
    const value = parseFloat(amount).toLocaleString('en-IN', {
        style: 'decimal',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
    })
    return value
}
export const amountConverterNODecimal = (amount) => {
    const value = parseFloat(amount).toLocaleString('en-IN', {
        style: 'decimal',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
    })
    return value
}

export const adminExpCategory = [
    { label: 'FOOD', value: 'food' },
    { label: 'SALARY', value: 'salary' },
    { label: 'MARKETING', value: 'marketing' },
    { label: 'WAREHOUSE', value: 'warehouse' },
    { label: 'RENT', value: 'rent' },
    { label: 'MISCELLANEOUS', value: 'miscellaneous' },
    { label: 'TECH', value: 'tech' }
]

export const pickupExpenseCategory = [
    {
        value: 'food',
        name: 'Food'
    },
    { value: 'travelling', name: 'Travelling' },
    { value: 'vehicle-expense', name: 'Vehicle expense' },
    { value: 'repair', name: 'Repair' },
    { value: 'others', name: 'Others' }
]

export const allModules = [
    { label: 'Dashboard', value: 'dashboard' },
    { label: 'Admin users', value: 'admin_users' },
    { label: 'Users', value: 'users' },
    { label: 'User list', value: 'users_list' },
    {
        label: 'Businesses',
        value: 'businesses'
    },
    { label: 'Active businesses', value: 'active' },
    { label: 'Pending businesses', value: 'pending' },
    { label: 'Rejected businesses', value: 'rejected' },
    { label: 'Slots', value: 'slots' },
    { label: 'Feeds', value: 'feeds' },
    {
        label: 'Pickup orders',
        value: 'pickup_orders'
    },
    { label: 'Pickup ongoing', value: 'pickup_ongoing' },
    { label: 'Pickup completed', value: 'pickup_completed' },
    { label: 'Pickup cancelled', value: 'pickup_cancelled' },
    {
        label: 'B2B Pickup orders',
        value: 'b2b_pickup_orders'
    },
    { label: 'B2B ongoing', value: 'business_ongoing' },
    { label: 'B2B completed', value: 'business_completed' },
    { label: 'B2B cancelled', value: 'business_cancelled' },
    {
        label: 'Eco store orders',
        value: 'eco_store_orders'
    },
    { label: 'Current orders', value: 'user_order_received' },
    { label: 'Ordered by Vendors', value: 'vendor_order_placed' },
    { label: 'Order in warehouse', value: 'items_received' },
    { label: 'Assigned to delivery', value: 'executive_assigned' },
    { label: 'Out for delivery', value: 'executive_out_for_delivery' },
    { label: 'Completed orders', value: 'order_delivered' },
    { label: 'Cancelled orders', value: 'cancelled' },
    {
        label: 'Replacement orders',
        value: 'replacement_orders'
    },
    { label: 'Replacement requested', value: 'replace_requested' },
    { label: 'Replacement completed', value: 'replaced' },
    {
        label: 'Customer issues',
        value: 'customer_issues'
    },
    { label: 'Customer issues users', value: 'query_users' },
    { label: 'Customer issues business', value: 'query_business' },
    { label: 'Customer issues Pickups / Delivery agents', value: 'query_pickups' },
    { label: 'Customer issues warehouse manager', value: 'query_warehouse_manager' },
    { label: 'Customer issues driver', value: 'query_driver' },
    {
        label: 'Master directory',
        value: 'master_directory'
    },
    {
        label: 'Cancellation reason',
        value: 'cancellation_reason'
    },
    { label: 'Cancel Pickup orders', value: 'c_pickeup' },
    { label: 'Cancel eco store', value: 'c_eco_store' },
    { label: 'Pickup executive', value: 'pickup_executive' },
    { label: 'Executive comments', value: 'executive_comments' },
    { label: 'Driver', value: 'driver' },
    { label: 'Warehouse / Manager', value: 'warehouse_manager' },
    { label: 'Eco store', value: 'eco_store' },
    { label: 'Ratecard', value: 'rate_card' },
    { label: 'B2B Ratecard', value: 'b2b_rate_card' },
    { label: 'Payment mode', value: 'payment_modes' },
    { label: 'NGOs', value: 'NGOs' },
    { label: 'Configuration', value: 'configuration' },
    { label: 'Outward client', value: 'outward_client' },
    { label: 'Groups / Pincodes', value: 'groups_pincodes' },
    { label: 'Festive offers', value: 'festive_offers' },
    {
        label: 'Settlements',
        value: 'settlements'
    },
    { label: 'Pickup expenses', value: 'pickup_expenses' },
    { label: 'Admin expenses', value: 'admin_expenses' },
    { label: 'NGO payments', value: 'NGO_payments' },
    { label: 'Referrals', value: 'referral_list' },
    { label: 'Warehouse', value: 'warehouse' }
]

export const accessiableModuleOptions = [
    { label: 'Dashboard', value: 'dashboard' },
    { label: 'Admin users', value: 'admin_users' },
    { label: 'Users', value: 'users', children: [{ label: 'User list', value: 'users_list' }] },
    {
        label: 'Businesses',
        value: 'businesses',
        children: [
            { label: 'Active businesses', value: 'active' },
            { label: 'Pending businesses', value: 'pending' },
            { label: 'Rejected businesses', value: 'rejected' }
        ]
    },
    { label: 'Slots', value: 'slots' },
    { label: 'Feeds', value: 'feeds' },
    {
        label: 'Pickup orders',
        value: 'pickup_orders',
        children: [
            { label: 'Pickup ongoing', value: 'pickup_ongoing' },
            { label: 'Pickup completed', value: 'pickup_completed' },
            { label: 'Pickup cancelled', value: 'pickup_cancelled' }
        ]
    },
    {
        label: 'B2B Pickup orders',
        value: 'b2b_pickup_orders',
        children: [
            { label: 'B2B ongoing', value: 'business_ongoing' },
            { label: 'B2B completed', value: 'business_completed' },
            { label: 'B2B cancelled', value: 'business_cancelled' }
        ]
    },
    {
        label: 'Eco store orders',
        value: 'eco_store_orders',
        children: [
            { label: 'Current orders', value: 'user_order_received' },
            { label: 'Ordered by Vendors', value: 'vendor_order_placed' },
            { label: 'Order in warehouse', value: 'items_received' },
            { label: 'Assigned to delivery', value: 'executive_assigned' },
            { label: 'Out for delivery', value: 'executive_out_for_delivery' },
            { label: 'Completed orders', value: 'order_delivered' },
            { label: 'Cancelled orders', value: 'cancelled' },
            {
                label: 'Replacement orders',
                value: 'replacement_orders',
                children: [
                    { label: 'Replacement requested', value: 'replace_requested' },
                    { label: 'Replacement completed', value: 'replaced' }
                ]
            }
        ]
    },
    {
        label: 'Customer issues',
        value: 'customer_issues',
        children: [
            { label: 'Customer issues users', value: 'query_users' },
            { label: 'Customer issues business', value: 'query_business' },
            { label: 'Customer issues Pickups / Delivery agents', value: 'query_pickups' },
            { label: 'Customer issues warehouse manager', value: 'query_warehouse_manager' },
            { label: 'Customer issues driver', value: 'query_driver' }
        ]
    },
    {
        label: 'Master directory',
        value: 'master_directory',
        children: [
            {
                label: 'Cancellation reason',
                value: 'cancellation_reason',
                children: [
                    { label: 'Cancel Pickup orders', value: 'c_pickeup' },
                    { label: 'Cancel eco store', value: 'c_eco_store' }
                ]
            },
            { label: 'Pickup executive', value: 'pickup_executive' },
            { label: 'Executive comments', value: 'executive_comments' },
            { label: 'Driver', value: 'driver' },
            { label: 'Warehouse / Manager', value: 'warehouse_manager' },
            { label: 'Eco store', value: 'eco_store' },
            { label: 'Ratecard', value: 'rate_card' },
            { label: 'B2B Ratecard', value: 'b2b_rate_card' },
            { label: 'Payment mode', value: 'payment_modes' },
            { label: 'NGOs', value: 'NGOs' },
            { label: 'Configuration', value: 'configuration' },
            { label: 'Outward client', value: 'outward_client' },
            { label: 'Groups / Pincodes', value: 'groups_pincodes' },
            { label: 'Festive offers', value: 'festive_offers' }
        ]
    },
    {
        label: 'Settlements',
        value: 'settlements',
        children: [
            { label: 'Pickup expenses', value: 'pickup_expenses' },
            { label: 'Admin expenses', value: 'admin_expenses' },
            { label: 'NGO payments', value: 'NGO_payments' },
            { label: 'Referrals', value: 'referral_list' }
        ]
    },
    { label: 'Warehouse', value: 'warehouse', children: null }
]

// placed
import user_placed_M from '../assets/map/ordericons/morning_u_placed.png'
import user_placed_A from '../assets/map/ordericons/afternoon_u_placed.png'
import user_placed_E from '../assets/map/ordericons/evening_u_placed.png'
import user_placed_N from '../assets/map/ordericons/night_u_placed.png'
// Assigned
import user_assigned_M from '../assets/map/ordericons/morning_u_assigned.png'
import user_assigned_A from '../assets/map/ordericons/afternoon_u_assigned.png'
import user_assigned_E from '../assets/map/ordericons/evening_u_assigned.png'
import user_assigned_N from '../assets/map/ordericons/night_u_assigned.png'
// OnRoute
import user_onroute_M from '../assets/map/ordericons/morning_u_onroute.png'
import user_onroute_A from '../assets/map/ordericons/afternoon_u_onroute.png'
import user_onroute_E from '../assets/map/ordericons/evening_u_onroute.png'
import user_onroute_N from '../assets/map/ordericons/night_u_onroute.png'
// Arrived
import user_arrived_M from '../assets/map/ordericons/morning_u_arrived.png'
import user_arrived_A from '../assets/map/ordericons/afternoon_u_arrived.png'
import user_arrived_E from '../assets/map/ordericons/evening_u_arrived.png'
import user_arrived_N from '../assets/map/ordericons/night_u_arrived.png'
// Inprogress
import user_inprogress_M from '../assets/map/ordericons/morning_u_inprogress.png'
import user_inprogress_A from '../assets/map/ordericons/afternoon_u_inprogress.png'
import user_inprogress_E from '../assets/map/ordericons/evening_u_inprogress.png'
import user_inprogress_N from '../assets/map/ordericons/night_u_inprogress.png'
//Closed
// import user_closed_M from '../assets/map/ordericons/morning_u_closed.png'
// import user_closed_A from '../assets/map/ordericons/afternoon_u_closed.png'
// import user_closed_E from '../assets/map/ordericons/evening_u_closed.png'
// import user_closed_N from '../assets/map/ordericons/night_u_closed.png'

// Completed
import user_completed_M from '../assets/map/ordericons/morning_u_completed.png'
import user_completed_A from '../assets/map/ordericons/afternoon_u_completed.png'
import user_completed_E from '../assets/map/ordericons/evening_u_completed.png'
import user_completed_N from '../assets/map/ordericons/night_u_completed.png'

// b2b placed
import b2b_placed_M from '../assets/map/ordericons/morning_b_placed.png'
import b2b_placed_A from '../assets/map/ordericons/afternoon_b_placed.png'
import b2b_placed_E from '../assets/map/ordericons/evening_b_placed.png'
import b2b_placed_N from '../assets/map/ordericons/night_b_placed.png'
// b2b Assigned
import b2b_assigned_M from '../assets/map/ordericons/morning_b_assigned.png'
import b2b_assigned_A from '../assets/map/ordericons/afternoon_b_assigned.png'
import b2b_assigned_E from '../assets/map/ordericons/evening_b_assigned.png'
import b2b_assigned_N from '../assets/map/ordericons/night_b_assigned.png'
// b2b OnRoute
import b2b_onroute_M from '../assets/map/ordericons/morning_b_onroute.png'
import b2b_onroute_A from '../assets/map/ordericons/afternoon_b_onroute.png'
import b2b_onroute_E from '../assets/map/ordericons/evening_b_onroute.png'
import b2b_onroute_N from '../assets/map/ordericons/night_b_onroute.png'
// b2b Arrived
import b2b_arrived_M from '../assets/map/ordericons/morning_b_arrived.png'
import b2b_arrived_A from '../assets/map/ordericons/afternoon_b_arrived.png'
import b2b_arrived_E from '../assets/map/ordericons/evening_b_arrived.png'
import b2b_arrived_N from '../assets/map/ordericons/night_b_arrived.png'
// b2b Inprogress
import b2b_inprogress_M from '../assets/map/ordericons/morning_b_inprogress.png'
import b2b_inprogress_A from '../assets/map/ordericons/afternoon_b_inprogress.png'
import b2b_inprogress_E from '../assets/map/ordericons/evening_b_inprogress.png'
import b2b_inprogress_N from '../assets/map/ordericons/night_b_inprogress.png'
//Closed
// import b2b_closed_M from '../assets/map/ordericons/morning_b_closed.png'
// import b2b_closed_A from '../assets/map/ordericons/afternoon_b_closed.png'
// import b2b_closed_E from '../assets/map/ordericons/evening_b_closed.png'
// import b2b_closed_N from '../assets/map/ordericons/night_b_closed.png'

// b2b Completed
import b2b_completed_M from '../assets/map/ordericons/morning_b_completed.png'
import b2b_completed_A from '../assets/map/ordericons/afternoon_b_completed.png'
import b2b_completed_E from '../assets/map/ordericons/evening_b_completed.png'
import b2b_completed_N from '../assets/map/ordericons/night_b_completed.png'

import noDataIcon from '../assets/map/unavailable.png'

export const getOrderStatusIcon = (marker, location) => {
    const slotLabel = marker?.ts_label
    const orderStatus = marker?.order_status
    const userType = location?.state?.type

    const completedUserIcons = {
        'Morning Slot': user_completed_M,
        'Afternoon Slot': user_completed_A,
        'Evening Slot': user_completed_E,
        'Night Slot': user_completed_N,
        B: user_completed_M,
        A: user_completed_A
    }
    const userIcons = {
        placed: {
            'Morning Slot': user_placed_M,
            'Afternoon Slot': user_placed_A,
            'Evening Slot': user_placed_E,
            'Night Slot': user_placed_N,
            B: user_placed_M,
            A: user_placed_A,
            'Morning Slot Selected': user_placed_M,
            'Afternoon Slot Selected': user_placed_A
        },
        assigned: {
            'Morning Slot': user_assigned_M,
            'Afternoon Slot': user_assigned_A,
            'Evening Slot': user_assigned_E,
            'Night Slot': user_assigned_N,
            B: user_assigned_M,
            A: user_assigned_A
        },
        onRoute: {
            'Morning Slot': user_onroute_M,
            'Afternoon Slot': user_onroute_A,
            'Evening Slot': user_onroute_E,
            'Night Slot': user_onroute_N,
            B: user_onroute_M,
            A: user_onroute_A
        },
        arrived: {
            'Morning Slot': user_arrived_M,
            'Afternoon Slot': user_arrived_A,
            'Evening Slot': user_arrived_E,
            'Night Slot': user_arrived_N,
            B: user_arrived_M,
            A: user_arrived_A
        },
        inProgress: {
            'Morning Slot': user_inprogress_M,
            'Afternoon Slot': user_inprogress_A,
            'Evening Slot': user_inprogress_E,
            'Night Slot': user_inprogress_N,
            B: user_inprogress_M,
            A: user_inprogress_A
        },
        closed: completedUserIcons,
        completed: completedUserIcons,
        loaded: completedUserIcons,
        un_loading: completedUserIcons,
        reached_warehouse: completedUserIcons,
        un_loaded: completedUserIcons
    }

    const completedB2BIcons = {
        'Morning Slot': b2b_completed_M,
        'Afternoon Slot': b2b_completed_A,
        'Evening Slot': b2b_completed_E,
        'Night Slot': b2b_completed_N,
        B: b2b_completed_M,
        A: b2b_completed_A
    }
    const b2bIcons = {
        placed: {
            'Morning Slot': b2b_placed_M,
            'Afternoon Slot': b2b_placed_A,
            'Evening Slot': b2b_placed_E,
            'Night Slot': b2b_placed_N,
            B: b2b_placed_M,
            A: b2b_placed_A
        },
        assigned: {
            'Morning Slot': b2b_assigned_M,
            'Afternoon Slot': b2b_assigned_A,
            'Evening Slot': b2b_assigned_E,
            'Night Slot': b2b_assigned_N,
            B: b2b_assigned_M,
            A: b2b_assigned_A
        },
        onRoute: {
            'Morning Slot': b2b_onroute_M,
            'Afternoon Slot': b2b_onroute_A,
            'Evening Slot': b2b_onroute_E,
            'Night Slot': b2b_onroute_N,
            B: b2b_onroute_M,
            A: b2b_onroute_A
        },
        arrived: {
            'Morning Slot': b2b_arrived_M,
            'Afternoon Slot': b2b_arrived_A,
            'Evening Slot': b2b_arrived_E,
            'Night Slot': b2b_arrived_N,
            B: b2b_arrived_M,
            A: b2b_arrived_A
        },
        inProgress: {
            'Morning Slot': b2b_inprogress_M,
            'Afternoon Slot': b2b_inprogress_A,
            'Evening Slot': b2b_inprogress_E,
            'Night Slot': b2b_inprogress_N,
            B: b2b_inprogress_M,
            A: b2b_inprogress_A
        },
        closed: completedB2BIcons,
        completed: completedB2BIcons,
        loaded: completedB2BIcons,
        un_loading: completedB2BIcons,
        reached_warehouse: completedB2BIcons,
        un_loaded: completedB2BIcons
    }

    const iconType = userType === 'pickupOrder' ? userIcons : b2bIcons

    return iconType[orderStatus]?.[slotLabel] || noDataIcon
}

export const disabledAfterTodayDates = (current) => {
    // disable upcoming dates
    return current > moment().endOf('day')
}
export const disabledBeforeDate = (current) => {
    // Disable dates before today
    return current && current < moment().startOf('day')
}
export const toCamelCase = (str) => {
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
}

export const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

export const orderMapBadge = [
    {
        label: 'Placed',
        color: '#B2790B'
    },
    {
        label: 'Assigned',
        color: '#1890FF'
    },
    {
        label: 'On Route',
        color: '#FADB14'
    },
    {
        label: 'Arrived',
        color: '#FAAD14'
    },
    {
        label: 'Inprogress',
        color: '#FA8C16'
    },
    {
        label: 'Completed',
        color: '#52C41A'
    },
    { label: 'Cancelled', color: '#FF3333' },
    { label: 'Rescheduled', color: '#00feff' }
]
