import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useQuery } from 'react-query'
import { getPickTimeSlotsWithoutDate } from '../cmpPickups/api-helper/pickups-helper'
import { message } from 'antd'

export const globalStates = createContext()
GlobalStates.propTypes = {
    children: PropTypes.node
}
export default function GlobalStates({ children }) {
    const userToken = localStorage.getItem('myToken')
    const [tabState, setTabState] = useState('0')

    // User Management State ============>
    const [userPagination, setUserPagination] = useState({ current: 1, pageSize: 10, total: 0 })
    const [userSearch, setUserSearch] = useState('')
    const [userFilter, setUserFilter] = useState('')
    const [userTabKey, setUserTabKey] = useState('users')
    const [referalsPagination, setReferalsPagination] = useState({ current: 1, pageSize: 10, total: 0 })
    const [referalsSearch, setReferalsSearch] = useState('')
    const [UP_TabKey, setUP_TabKey] = useState('pickups')
    const [UP_PickupPagination, setUP_PickupPagination] = useState({
        pageSize: 10,
        current: 1,
        total: 0
    })
    const [UP_EStorePagination, setUP_EStorePagination] = useState({
        pageSize: 10,
        current: 1,
        total: 0
    })
    const [UP_AddressPagination, setUP_AddressPagination] = useState({
        pageSize: 10,
        current: 1,
        total: 0
    })
    const [UP_ReferralDetailsPagination, setUP_ReferralDetailsPagination] = useState({
        pageSize: 10,
        current: 1,
        total: 0
    })
    const [UP_EStoreStatusFilter, setUP_EStoreStatusFilter] = useState('')

    //B2B State ===>
    const [b2BPagination, setB2BPagination] = useState({ current: 1, pageSize: 10, total: 0 })
    const [b2bSearch, setB2bSearch] = useState('')

    //Feeds State ===>
    const [feedsPagination, setFeedsPagination] = useState({ current: 1, pageSize: 10, total: 0 })
    const [feedSearch, setFeedSearch] = useState('')

    //Pickup order & B2B pickup order State ===>
    const [u_Pickup_Pagination, setU_Pickup_Pagination] = useState({ current: 1, pageSize: 100, total: 0 })
    const [b2B_Pickup_Pagination, setB2B_Pickup_Pagination] = useState({ current: 1, pageSize: 100, total: 0 })
    const [pickUpOrderDate, setPickUpOrderDate] = useState('')
    const [pickUpCompleteOrderDate, setPickUpCompleteOrderDate] = useState([])
    const [rateFilter, setRateFilter] = useState('')
    const [b2BPaymentFilter, setB2BPaymentFilter] = useState('')
    const [pickupPaymentFilter, setPickupPaymentFilter] = useState('')
    const [B2CPickupFilterExe, setB2CPickupFilterExe] = useState([])
    const [B2BPickupFilterExe, setB2BPickupFilterExe] = useState([])
    const [groupIdFilter, setGroupIdFilter] = useState('')
    const [openMapFilterDropdown, setOpenMapFilterDropdown] = useState(false)
    const [orderMapFilter, setOrderMapFilter] = useState({})
    const [selectedPSlot, setSelectedPSlot] = useState('')
    const [searchPOrder, setSearchPOrder] = useState('')
    const [selectedB2BPSlot, setSelectedB2BPSlot] = useState('')
    const [searchB2BPOrder, setSearchB2BPOrder] = useState('')
    const [pickupOrderTabKey, setPickupOrderTabKey] = useState('')
    const [b2bPickupOrderTabKey, setB2bPickupOrderTabKey] = useState('')
    const [multipleAssign, setMultipleAssign] = useState(false)
    const [orderIDS, setOrderIDS] = useState([])
    const [openMultiAssignDrawer, setOpenMultiAssignDrawer] = useState(false)
    const [multipleOrderDetailsModal, setMultipleOrderDetailsModal] = useState(false)
    const [changedValues, setChangedValues] = useState({})

    //Eco Order State ===>
    const [ecoOrderpagination, setEcoOrderPagination] = useState({ current: 1, pageSize: 100, total: 0 })
    const [searchECOOrder, setSearchECOOrder] = useState('')
    const [ecoStoreOrderDate, setEcoStoreOrderDate] = useState('')

    // Customer support State ===>
    const [CS_TabState, setCS_TabState] = useState('open')
    const [CS_Search, setCS_Search] = useState('')
    const [CS_Pagination, setCS_Pagination] = useState({ pageSize: 10, current: 1, total: 0 })

    // Pickup Executive State ===>
    const [p_Exe_Pagination, setP_Exe_Pagination] = useState({ current: 1, pageSize: 10, total: 0 })
    const [p_Exe_Search, setP_Exe_Search] = useState('')
    const [p_Exe_Filter, setP_Exe_Filter] = useState('')

    //Driver State ====>
    const [driverPagination, setDriverPagination] = useState({ current: 1, pageSize: 10, total: 0 })
    const [driverSearch, setDriverSearch] = useState('')
    const [driverFilter, setDriverFilter] = useState('')
    const [driverTabState, setDriverTabState] = useState('loads')
    const [d_Order_Pagination, setD_Order_Pagination] = useState({ current: 1, pageSize: 10, total: 0 })
    const [d_Order_Search, setD_Order_Search] = useState('')

    //Warehouse State ====>
    const [warhouseTabstate, setWarhouseTabstate] = useState('warehouse')
    const [warehouse_Pagination, setWarehouse_Pagination] = useState({ current: 1, pageSize: 10, total: 0 })
    const [warehouseSearch, setWarehouseSearch] = useState('')
    const [warehousefilter, setWarehouseFilter] = useState('')
    const [w_Manager_Pagination, setW_Manager_Pagination] = useState({ current: 1, pageSize: 10, total: 0 })
    const [managerSearch, setManagerSearch] = useState('')
    const [managerfilter, setManagerFilter] = useState('')
    const [warehouseInnerState, setwarehouseInnerstate] = useState('stock-summary')
    const [totalStock, setTotalStock] = useState(0)
    const [ledger_Pagination, setLedger_Pagination] = useState({ current: 1, pageSize: 10, total: 0 })
    //Inward States
    const [inward_Pagination, setInward_Pagination] = useState({ current: 1, pageSize: 10, total: 0 })
    const [inwardFilterStatus, setInwardFilterStatus] = useState('')
    const [inwardDateFilter, setInwardDateFilter] = useState([])
    //Outward States
    const [outwardTab, setOutwardTab] = useState('individual')
    const [outward_Pagination, setOutward_Pagination] = useState({ current: 1, pageSize: 10, total: 0 })
    const [searchOutward, setSearchOutward] = useState('')
    const [outwardDateFilter, setOutwardDateFilter] = useState([])
    const [outwardfilterStatus, setOutwardFilterStatus] = useState('')

    //ECO Store states ===>
    const [ecoStoreTabState, setEcoStoreTabState] = useState('cat')
    const [EStoreCatPagination, setEStoreCatPagination] = useState({ pageSize: 10, current: 1, total: 0 })
    const [EStoreCatSearch, setEStoreCatSearch] = useState('')
    const [EStoreBrandPagination, setEStoreBrandPagination] = useState({ pageSize: 10, current: 1, total: 0 })
    const [EStoreBrandSearch, setEStoreBrandSearch] = useState('')
    const [EStoreBrandFilter, setEStoreBrandFilter] = useState('')
    const [EStoreProductPagination, setEStoreProductPagination] = useState({ pageSize: 10, current: 1, total: 0 })
    const [EStoreProductSearch, setEStoreProductSearch] = useState('')
    const [EStoreProductFilter, setEStoreProductFilter] = useState('')
    const [EStoreBrandInnerPagination, setEStoreBrandInnerPagination] = useState({ pageSize: 10, current: 1, total: 0 })
    const [EStoreBrandInnerFilter, setEStoreBrandInnerFilter] = useState('')
    const [EStoreBrandInnerSearch, setEStoreBrandInnerSearch] = useState('')
    const [productmodal2Visible, setProductModal2Visible] = useState(false)
    const [productmodalDetails, setProductModalDetails] = useState({})
    const [brandCard, setbrandCard] = useState(false)

    //Rate Card States ===>
    const [rate_Card_Pagination, setRate_Card_Pagination] = useState({ current: 1, pageSize: 10, total: 0 })
    const [rate_CardSearch, setRate_CardSearch] = useState('')
    const [rate_CardFilter, setRate_CardFilter] = useState('')
    const [RC_ProductPagination, setRC_ProductPagination] = useState({ pageSize: 10, current: 1, total: 0 })
    const [RC_ProductSearch, setRC_ProductSearch] = useState('')
    const [RC_ProductFilter, setRC_ProductFilter] = useState('')

    // NGOs States ===>
    const [ngoPagination, setNgoPagination] = useState({ current: 1, pageSize: 10, total: 0 })
    const [ngoSearch, setNgoSearch] = useState('')
    const [ngoTabKey, setNgoTabkey] = useState('profile')
    const [ngoPickupPagination, setNgoPickupPagination] = useState({ current: 1, pageSize: 10, total: 0 })
    const [ngoPickupSearch, setNgoPickupSearch] = useState('')
    const [donationMonthYear, setDonationMonthYear] = useState(moment())
    const [donationPagination, setDonationPagination] = useState({ current: 1, pageSize: 10, total: 0 })
    const [ngoUserPagination, setNgoUserPagination] = useState({ current: 1, pageSize: 10, total: 0 })
    const [ngoUserSearch, setNgoUserSearch] = useState('')

    // pickup Exepense states == >
    const [pickupExpSearch, setPickupExpSearch] = useState('')
    const [pickupExpFilter, setPickupExpFilter] = useState('')
    const [pickupExpMonthYearFilter, setPickupExpMonthYearFilter] = useState(moment().format('MM-YYYY'))
    const [pickupExpExecutiveFilter, setPickupExpExecutiveFilter] = useState('')
    const [pickupExpTypeFilter, setPickupExpTypeFilter] = useState('')
    const [pickupExpPagination, setPickupExpPagination] = useState({ current: 1, pageSize: 100, total: 0 })

    // Pending KpI sidebar states ===>
    const [b2b_Pending_Request, setb2b_Pending_Request] = useState('')
    const [open_C_Issues, setOpen_C_Issues] = useState({})
    const [ecoPlacedOrders, setEcoPlacedOrders] = useState('')

    // Image preview states ===>
    const [previewOpen, setPreviewOpen] = useState(false)
    const [previewImage, setPreviewImage] = useState('')
    const [previewTitle, setPreviewTitle] = useState('')

    // Pincode Groups states ===>
    const [groupTabState, setGroupTabState] = useState('group')
    const [pincodeSearch, setPincodeSearch] = useState('')
    const [pincodePagination, setPincodePagination] = useState({ current: 1, pageSize: 10, total: 0 })

    const { data: initialTimeSlots } = useQuery(['initialTimeSlots'], () => getPickTimeSlotsWithoutDate(), {
        onError: (error) => message.error(error.message),
        onSuccess: (data) => {
            if (data && data?.length > 0) {
                const timeSlotid = data[0]?.id ? data[0]?.id : ''
                setPickupOrderTabKey(timeSlotid)
                setB2bPickupOrderTabKey(timeSlotid)
            }
        },
        keepPreviousData: true,
        enabled: !!userToken,
        retry: 0
    })
    const { data: slots } = useQuery(['timeSlotForMap'], () => getPickTimeSlotsWithoutDate(), {
        keepPreviousData: true,
        enabled: !!userToken,
        retry: 0
    })

    return (
        <globalStates.Provider
            value={{
                tabState,
                setTabState,

                //Users states ===>
                userPagination,
                setUserPagination,
                userSearch,
                setUserSearch,
                userFilter,
                setUserFilter,
                userTabKey,
                setUserTabKey,
                referalsPagination,
                setReferalsPagination,
                referalsSearch,
                setReferalsSearch,
                UP_TabKey,
                setUP_TabKey,
                UP_PickupPagination,
                setUP_PickupPagination,
                UP_EStorePagination,
                setUP_EStorePagination,
                UP_EStoreStatusFilter,
                setUP_EStoreStatusFilter,
                UP_AddressPagination,
                setUP_AddressPagination,
                UP_ReferralDetailsPagination,
                setUP_ReferralDetailsPagination,

                //B2B states  ===>
                b2BPagination,
                setB2BPagination,
                b2bSearch,
                setB2bSearch,

                //Feeds states ===>
                feedsPagination,
                setFeedsPagination,
                feedSearch,
                setFeedSearch,

                // Pickup states & B2b Pickup states ===>
                u_Pickup_Pagination,
                setU_Pickup_Pagination,
                b2B_Pickup_Pagination,
                setB2B_Pickup_Pagination,
                pickUpOrderDate,
                setPickUpOrderDate,
                pickUpCompleteOrderDate,
                setPickUpCompleteOrderDate,
                pickupPaymentFilter,
                setPickupPaymentFilter,
                b2BPaymentFilter,
                setB2BPaymentFilter,
                rateFilter,
                setRateFilter,
                B2CPickupFilterExe,
                setB2CPickupFilterExe,
                B2BPickupFilterExe,
                setB2BPickupFilterExe,
                groupIdFilter,
                setGroupIdFilter,
                openMapFilterDropdown,
                setOpenMapFilterDropdown,
                orderMapFilter,
                setOrderMapFilter,
                selectedPSlot,
                setSelectedPSlot,
                searchPOrder,
                setSearchPOrder,
                selectedB2BPSlot,
                setSelectedB2BPSlot,
                searchB2BPOrder,
                setSearchB2BPOrder,
                pickupOrderTabKey,
                setPickupOrderTabKey,
                b2bPickupOrderTabKey,
                setB2bPickupOrderTabKey,
                multipleAssign,
                setMultipleAssign,
                orderIDS,
                setOrderIDS,
                openMultiAssignDrawer,
                setOpenMultiAssignDrawer,
                multipleOrderDetailsModal,
                setMultipleOrderDetailsModal,
                changedValues,
                setChangedValues,

                //ECO Order states START ===>
                ecoOrderpagination,
                setEcoOrderPagination,
                searchECOOrder,
                setSearchECOOrder,
                ecoStoreOrderDate,
                setEcoStoreOrderDate,

                //Customer support states ===>
                CS_TabState,
                setCS_TabState,
                CS_Search,
                setCS_Search,
                CS_Pagination,
                setCS_Pagination,

                //Pickup Executives states ===>
                p_Exe_Pagination,
                setP_Exe_Pagination,
                p_Exe_Filter,
                setP_Exe_Filter,
                p_Exe_Search,
                setP_Exe_Search,

                //Driver states ===>
                driverTabState,
                setDriverTabState,
                driverPagination,
                setDriverPagination,
                driverSearch,
                setDriverSearch,
                driverFilter,
                setDriverFilter,
                d_Order_Pagination,
                setD_Order_Pagination,
                d_Order_Search,
                setD_Order_Search,

                //Warehouse states ===>
                warhouseTabstate,
                setWarhouseTabstate,
                warehouseInnerState,
                setwarehouseInnerstate,
                warehouse_Pagination,
                setWarehouse_Pagination,
                w_Manager_Pagination,
                setW_Manager_Pagination,
                warehouseSearch,
                setWarehouseSearch,
                warehousefilter,
                setWarehouseFilter,
                managerSearch,
                setManagerSearch,
                managerfilter,
                setManagerFilter,
                totalStock,
                setTotalStock,
                ledger_Pagination,
                setLedger_Pagination,
                //Inward states ==>
                inward_Pagination,
                setInward_Pagination,
                inwardFilterStatus,
                setInwardFilterStatus,
                inwardDateFilter,
                setInwardDateFilter,
                //Outward states ==>
                outwardTab,
                setOutwardTab,
                outward_Pagination,
                setOutward_Pagination,
                searchOutward,
                setSearchOutward,
                outwardDateFilter,
                setOutwardDateFilter,
                outwardfilterStatus,
                setOutwardFilterStatus,

                //ECO Store states ===>
                ecoStoreTabState,
                setEcoStoreTabState,
                EStoreCatPagination,
                setEStoreCatPagination,
                EStoreCatSearch,
                setEStoreCatSearch,
                EStoreBrandPagination,
                setEStoreBrandPagination,
                EStoreBrandSearch,
                setEStoreBrandSearch,
                EStoreBrandFilter,
                setEStoreBrandFilter,
                EStoreProductPagination,
                setEStoreProductPagination,
                EStoreProductSearch,
                setEStoreProductSearch,
                EStoreProductFilter,
                setEStoreProductFilter,
                EStoreBrandInnerPagination,
                setEStoreBrandInnerPagination,
                EStoreBrandInnerFilter,
                setEStoreBrandInnerFilter,
                EStoreBrandInnerSearch,
                setEStoreBrandInnerSearch,
                brandCard,
                setbrandCard,
                productmodal2Visible,
                setProductModal2Visible,
                productmodalDetails,
                setProductModalDetails,

                //Rate Card states ===>
                rate_Card_Pagination,
                setRate_Card_Pagination,
                rate_CardSearch,
                setRate_CardSearch,
                rate_CardFilter,
                setRate_CardFilter,
                RC_ProductPagination,
                setRC_ProductPagination,
                RC_ProductSearch,
                setRC_ProductSearch,
                RC_ProductFilter,
                setRC_ProductFilter,

                // Ngos states ===>
                ngoPagination,
                setNgoPagination,
                ngoSearch,
                setNgoSearch,
                ngoTabKey,
                setNgoTabkey,
                donationMonthYear,
                setDonationMonthYear,
                donationPagination,
                setDonationPagination,
                ngoPickupPagination,
                setNgoPickupPagination,
                ngoPickupSearch,
                setNgoPickupSearch,
                ngoUserPagination,
                setNgoUserPagination,
                ngoUserSearch,
                setNgoUserSearch,

                // Pickup expense States ===>
                pickupExpSearch,
                setPickupExpSearch,
                pickupExpFilter,
                setPickupExpFilter,
                pickupExpMonthYearFilter,
                setPickupExpMonthYearFilter,
                pickupExpTypeFilter,
                setPickupExpTypeFilter,
                pickupExpExecutiveFilter,
                setPickupExpExecutiveFilter,
                pickupExpPagination,
                setPickupExpPagination,

                // Pending KpI sidebar states ===>
                b2b_Pending_Request,
                setb2b_Pending_Request,
                open_C_Issues,
                setOpen_C_Issues,
                ecoPlacedOrders,
                setEcoPlacedOrders,

                // Image preview states ===>
                previewOpen,
                setPreviewOpen,
                previewImage,
                setPreviewImage,
                previewTitle,
                setPreviewTitle,

                // Pincode Group states ===>
                groupTabState,
                setGroupTabState,
                pincodeSearch,
                setPincodeSearch,
                pincodePagination,
                setPincodePagination,

                // timeslots ===>
                initialTimeSlots,
                slots
            }}>
            {children}
        </globalStates.Provider>
    )
}
